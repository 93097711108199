import React, { useState } from "react";
import './index.css'; 

const CalculadoraMatriz = () => {
  const [activeTab, setActiveTab] = useState('calculadora');
  const [coeficientes, setCoeficientes] = useState({
    a1: "", b1: "", c1: "",
    a2: "", b2: "", c2: ""
  });
  const [resultados, setResultados] = useState({
    determinante: null, x: null, y: null,
    determinanteX: null, determinanteY: null
  });
  const [mostrarComprobacion, setMostrarComprobacion] = useState(false);

  const cambiarPestaña = (pestaña) => {
    setActiveTab(pestaña);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoeficientes((prev) => ({ ...prev, [name]: value }));
  };

  const round = (value) => {
    return Number(Math.round(value + 'e2') + 'e-2').toFixed(2);
  };

  const calcularSistema = () => {
    const { a1, b1, c1, a2, b2, c2 } = coeficientes;

    const determinante = (a1 * b2) - (b1 * a2);
    const determinanteX = (c1 * b2) - (b1 * c2);
    const determinanteY = (a1 * c2) - (c1 * a2);

    if (determinante === 0) {
      alert("El sistema no tiene solución única (determinante es 0).");
      return;
    }

    const x = round(determinanteX / determinante);
    const y = round(determinanteY / determinante);

    setResultados({
      determinante: round(determinante),
      determinanteX: round(determinanteX),
      determinanteY: round(determinanteY),
      x,
      y
    });

    setMostrarComprobacion(false);
  };

  const realizarComprobacion = () => {
    const { a1, b1, c1, a2, b2, c2 } = coeficientes;
    const { x, y } = resultados;

    const resultado1 = a1 * x + b1 * y;
    const resultado2 = a2 * x + b2 * y;

    const resultado1Formateado = Number.isInteger(resultado1) ? resultado1.toString() : resultado1.toFixed(1);
    const resultado2Formateado = Number.isInteger(resultado2) ? resultado2.toString() : resultado2.toFixed(1);

    return (
      <div className="comprobacion">
        <h3>Comprobación:</h3>
        <p>Para la primera ecuación: {a1} * {x} + {b1} * {y} = {resultado1Formateado} (debería ser {c1})</p>
        <p>Para la segunda ecuación: {a2} * {x} + {b2} * {y} = {resultado2Formateado} (debería ser {c2})</p>
      </div>
    );
  };

  return (
    <div className="calculadora-container">
      <div className="tab-bar">
        <button onClick={() => cambiarPestaña('calculadora')} className={activeTab === 'calculadora' ? 'active' : ''}>
          Calculadora de Matrices
        </button>
      </div>

      {activeTab === 'calculadora' && (
        <div>
          <h1>Calculadora de Matrices</h1>

          <h2>Primera Ecuación (ax + by = c)</h2>
          <input type="number" name="a1" value={coeficientes.a1} onChange={handleChange} placeholder="a1" />
          <input type="number" name="b1" value={coeficientes.b1} onChange={handleChange} placeholder="b1" />
          <input type="number" name="c1" value={coeficientes.c1} onChange={handleChange} placeholder="c1" />

          <h2>Segunda Ecuación (dx + ey = f)</h2>
          <input type="number" name="a2" value={coeficientes.a2} onChange={handleChange} placeholder="a2" />
          <input type="number" name="b2" value={coeficientes.b2} onChange={handleChange} placeholder="b2" />
          <input type="number" name="c2" value={coeficientes.c2} onChange={handleChange} placeholder="c2" />

          <button onClick={calcularSistema}>Calcular</button>

          {resultados.determinante && (
            <div className="result">
              <h3>Matrices:</h3>
              <p>Matriz original para el determinante:</p>
              <pre>| {coeficientes.a1}   {coeficientes.b1} |\n| {coeficientes.a2}   {coeficientes.b2} |</pre>
              <p>= {coeficientes.a1} * {coeficientes.b2} - ({coeficientes.b1} * {coeficientes.a2}) = {resultados.determinante}</p>

              <p>Matriz para determinante de X:</p>
              <pre>| {coeficientes.c1}   {coeficientes.b1} |\n| {coeficientes.c2}   {coeficientes.b2} |</pre>
              <p>= {coeficientes.c1} * {coeficientes.b2} - ({coeficientes.b1} * {coeficientes.c2}) = {resultados.determinanteX}</p>

              <p>Matriz para determinante de Y:</p>
              <pre>| {coeficientes.a1}   {coeficientes.c1} |\n| {coeficientes.a2}   {coeficientes.c2} |</pre>
              <p>= {coeficientes.a1} * {coeficientes.c2} - ({coeficientes.c1} * {coeficientes.a2}) = {resultados.determinanteY}</p>

              <h3>Proceso de cálculo:</h3>
              <p>x = {resultados.determinanteX} / {resultados.determinante} = {resultados.x}</p>
              <p>y = {resultados.determinanteY} / {resultados.determinante} = {resultados.y}</p>

              <h3>Resultados:</h3>
              <p>x = {resultados.x}</p>
              <p>y = {resultados.y}</p>
            </div>
          )}

          {resultados.x && resultados.y && (
            <button onClick={() => setMostrarComprobacion(true)}>Realizar Comprobación</button>
          )}

          {mostrarComprobacion && realizarComprobacion()}
        </div>
      )}
    </div>
  );
};

export default CalculadoraMatriz;
